.App {
  display: flex;
  position: relative;
  height: 100%;
  /* flex-wrap: wrap; */
}
.content__main{
  padding: 24px;
  margin-top: 72px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 222px;
  overflow-y: auto;
  background-color: #f3f5f7;
}
.content__main.hide_menus{
  margin-left: 0;
  margin-top: 0;
  background-color: #fff;
}
.sidebar-collapsed .content__main {
  margin-left: 81px;
}
.tab-content {
  margin: 0 auto;
  max-width: 512px;
  padding-top: 40px;
}
@media (max-width: 721px) {
  body {
    overflow-y: auto;
  }
}


.panelarb {
  direction: rtl;
}