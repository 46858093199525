/* *:focus {
    outline: none !important;
    border: none;
    border-color: #fff !important;
  } */

body {
    font-size: 14px;
}

.css-1qqsdnr-MuiAutocomplete-root {
    max-width: 655px !important;
    width: 100% !important;
}

.mt_10 {
    margin-top: -10px;
}

.org_tab .nav-item {
    width: 50%;
}

.home_tab .nav-item {
    /* width: 8%; */
}

/* #case-list-tabs-tab-summary {
    border: 2px solid #1dce00;
    padding: 15px 0;
    position: relative;
} */
#case-list-tabs-tabpane-summary {
    color: #3f2783;
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}

#case-list-tabs-tabpane-shared.active {
    border: 2px solid #1dce00;
}

#case-list-tabs-tabpane-shared.active.show {
    color: #1dce00;
    border: 2px solid #1dce00;
    padding: 15px 0;
    position: relative;
}


#case-list-tabs-tabpane-shared {
    color: #3f2783;
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}

div#case-list-tabs-tabpane-completed {
    color: #3f2783;
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}

div#case-list-tabs-tabpane-pending {
    color: #3f2783;
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}

div#case-list-tabs-tabpane-expired {
    color: #3f2783;
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}

div#case-list-tabs-tabpane-incomplete {
    color: #3f2783;
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}


label.user-letter {
    width: 40px;
    height: 40px;
    margin: 0;
    background: #5e60ce;
    border-radius: 50%;
    line-height: 36px;
    border: 2px solid #3F2783;
    text-align: center;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

label.user-letter.large {
    width: 93px;
    height: 90px;
    line-height: 87px;
    font-size: 40px;
    border: 1.8px solid #3f2783;
}

.large img {

    width: 95px !important;
    height: 95px !important;
    margin: -3px;
    background: #5e60ce;
    border-radius: 50%;
    border: 2px solid #3F2783;

}


.sidenav_right label.user-letter.large {
    margin: 20px 0;
    width: 93px;
    height: 90px;
}

label.user-letter.small {
    width: 28px;
    height: 28px;
    line-height: 26px;
    font-size: 18px;
    position: relative;
    z-index: 2;
}

.user-letter-smProfile {
    width: 40px;
    height: 40px;
    margin: 0;
    background: #5e60ce;
    border-radius: 50%;
    line-height: 37px;
    border: 2px solid #3F2783;
    text-align: center;
    color: #fff;
    font-size: 32px;
    font-weight: 600;
}

.user-letter img {

    width: 44px;
    height: 44px;
    margin: -3px;
    background: #5e60ce;
    border-radius: 50%;

    border: 2px solid #3F2783;

}

.profile_pic_letter {
    font-size: 54px;
    color: #fff;
    background: linear-gradient(0deg, #5e60ce, #5e60ce);
    display: inline;
    border-radius: 50px;
    padding: 0px 25px;
    border: 1.8px solid #3f2783;
    font-weight: 600;
    width: 90px;
    height: 90px;
    line-height: 81px;
}

.form-control {
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 20px;
    color: #3f2783;
    padding: 0px 26px 0px 14px;
    height: 40px;
    background: transparent;
    margin-bottom: 12px;
    box-shadow: none;
    max-width: 285px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    white-space: break-spaces;
}

.form-control:focus {

    border-color: rgba(63, 39, 131, 0.7) !important;

}

.form-control:disabled,
.form-control[readonly] {
    background-color: #D9D4E6;
    border: none;
    opacity: 1;
    color: #7968A8;
}

.form-select {
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 20px;
    color: #3f2783;
    padding: 0px 26px 0px 14px;
    height: 40px;
    margin-bottom: 12px;
    width: 100%;
    box-shadow: none;
}

.form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 16px;
}

label,
.form-label {
    color: #3f2783;
    margin-bottom: 15px;
    font-weight: 600;
}

.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(63, 39, 131, 0.5);
}

.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(63, 39, 131, 0.5);
}

.form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(63, 39, 131, 0.5);
}

.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(63, 39, 131, 0.5);
}

.search-box .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(63, 39, 131, 0.3);
}

.search-box .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(63, 39, 131, 0.3);
}

.search-box .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(63, 39, 131, 0.3);
}

.search-box .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(63, 39, 131, 0.3);
}

.black {
    color: #000;
}

.custom_check_box [type="checkbox"] {
    position: absolute;
    opacity: 0;
}

.custom_check_box [type="checkbox"]+label {
    position: relative;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #3f2783;
}

.custom_check_box.large [type="checkbox"]+label {
    padding-left: 40px;
    padding-right: 40px;
    line-height: 25px;
}

.custom_check_box [type="checkbox"]+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 100%;
    background: #fff;
}

.custom_check_box.large [type="checkbox"]+label:before {
    width: 24px;
    height: 24px;
}

.custom_check_box [type="checkbox"]:checked+label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #3f2783;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.custom_check_box.tick [type="checkbox"]:checked+label:after {
    background: url(../images/check.png) no-repeat;
    background-color: #3f2783;
    background-position: center;
    width: 18px;
    height: 18px;
    top: 1px;
    left: 1px;
}

.custom_check_box.tick.large [type="checkbox"]:checked+label:after {
    width: 22px;
    height: 22px;
}

.custom_radio_box [type="radio"] {
    position: absolute;
    opacity: 0;
}

.custom_radio_box [type="radio"]+label {
    position: relative;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #3f2783;
}

.custom_radio_box [type="radio"]+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 100%;
    background: #fff;
}

.custom_radio_box [type="radio"]:checked+label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #3f2783;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.custom_radio_box.large [type="radio"]+label {
    padding-left: 40px !important;
    font-weight: 400;
}

.custom_radio_box.large [type="radio"]+label:before {
    width: 23px;
    height: 23px;
}

.custom_radio_box.large [type="radio"]:checked+label:after {
    width: 13px;
    height: 13px;
    top: 5.5px;
    left: 4.5px;
}

.custom_radio_box.tick [type="radio"]:checked+label:after {
    background: url(../images/check.png) no-repeat;
    background-color: #3f2783;
    background-position: center;
    width: 18px;
    height: 18px;
    top: 1px;
    left: 1px;
}

.custom_radio_box.tick.large [type="radio"]:checked+label:after {
    width: 22px;
    height: 22px;
}

.settings_main .custom_check_box [type="checkbox"]+label {
    padding-left: 40px;
    font-weight: 400;
    font-size: 14px;
}

.btn_disabled {
    width: 140px;
    height: 40px;
    border: 2px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;
    cursor: default !important;
    border-radius: 20px;
    pointer-events: none;
}

.btn_disabled:hover,
.btn_disabled:focus {
    border: 2px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;
    outline: none !important;
    pointer-events: none;
    text-decoration: none;
}


.btn_success {
    width: 140px;
    height: 40px;
    color: #fff;
    background: #0b780e;
    border-radius: 10px;
    border: 2px solid #0b780e;
    display: inline-block;
}

.btn_success:hover,
.btn_success:focus {
    background: transparent;
    color: #0b780e;
    outline: none;
    text-decoration: none;
    border: 2px solid #0b780e;
}

.btn_fill {
    width: 140px;
    height: 40px;
    color: #fff;
    background: #3f2783;
    border-radius: 10px;
    border: 2px solid #3f2783;
    display: inline-block;
}

.btn_fill:hover,
.btn_fill:focus {
    background: transparent;
    color: #3f2783;
    outline: none;
    text-decoration: none;
    border: 2px solid #3f2783;
}

.btn_danger {
    width: 140px;
    height: 40px;
    background: #ff0000;
    color: #fff;
    border-radius: 20px;
    border: 2px solid #ff0000;
    display: inline-block;
}

.btn_danger:hover,
.btn_danger:focus {
    background: transparent;
    color: #ff0000;
    outline: none;
    text-decoration: none;
    border: 2px solid #ff0000;
}

.btn_green {
    width: 140px;
    height: 40px;
    background: #6fcf97;
    color: #fff;
    border-radius: 20px;
    border: 2px solid #6fcf97;
    display: inline-block;
}

.btn_green:hover,
.btn_green:focus {
    background: transparent;
    color: #6fcf97;
    outline: none;
    text-decoration: none;
    border: 2px solid #6fcf97;
}

.btn_green_outline {
    width: 140px;
    height: 40px;
    background: transparent;
    color: #6fcf97;
    border-radius: 20px;
    border: 2px solid #6fcf97;
    display: inline-block;
}

.btn_green_outline:hover,
.btn_green_outline:focus {
    background: #6fcf97;
    color: #fff;
    outline: none;
    text-decoration: none;
    border: 2px solid #6fcf97;
}

.btn_nill {
    background: transparent;
    border: none;
    color: #3f2783;
}

.btn_nill:hover,
.btn_nill:focus {
    background: transparent;
    border: none;
    color: #3f2783;
}

button.btn {
    font-size: 14px;
}

.btn_outline {
    border: 2px solid #3f2783;
    color: #3f2783;
    border-radius: 50px;
    font-size: 14px;
    padding: 0 22px;
    height: 40px;
    line-height: 38px;
    background: transparent;
}

.btn_outline:hover {
    background: #3f2783 !important;
    color: #fff !important;
}

a,
a:hover {
    color: #3f2783;
    text-decoration: none;
}

.form-control::-webkit-input-placeholder {
    color: rgba(63, 39, 131, 0.7);
    font-size: 14px;
}

.form-control:-moz-placeholder {
    color: rgba(63, 39, 131, 0.7);
    font-size: 14px;
}

.form-control::-moz-placeholder {
    color: rgba(63, 39, 131, 0.7);
    font-size: 14px;
}

.form-control:-ms-input-placeholder {
    color: rgba(63, 39, 131, 0.7);
    font-size: 14px;
}

.form-select {
    font-size: 14px;
}

.multi_select_drp {
    max-width: 260px;
}

.multi_select_drp.br_10_multi .css-yk16xz-control,
.multi_select_drp.br_10_multi .css-1pahdxg-control,
.multi_select_drp.br_10_multi .css-1s2u09g-control {
    border-radius: 10px;
}

.multi_select_drp .css-yk16xz-control,
.multi_select_drp .css-1pahdxg-control,
.multi_select_drp .css-1s2u09g-control,
.time_picker .react-time-picker__wrapper {
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 20px;
    color: #3f2783;
    padding: 0;
    min-height: 40px;
    margin-bottom: 12px;
    width: 100%;
    box-shadow: none;
}

.multi_select_drp .css-1okebmr-indicatorSeparator,
.multi_select_drp .css-tlfecz-indicatorContainer,
.multi_select_drp span+.css-1gtu0rj-indicatorContainer {
    display: none;
}

.time_picker {
    margin-bottom: 6px;
}

.time_picker label,
.time_picker .form-label {
    color: #3f2783;
    margin-bottom: 15px;
    font-weight: 600;
    display: block;
}

.time_picker .react-time-picker__wrapper {
    width: 260px;
    padding: 0 15px;
}

.time_picker .react-time-picker__button svg {
    stroke: #3f2783;
}

.time_picker .react-time-picker__inputGroup__input {
    color: #3f2783;
}

.mr_35 {
    margin-right: 35px;
}

.mr_30 {
    margin-right: 30px;
}

.mr_20 {
    margin-right: 20px;
}

.mr_15 {
    margin-right: 15px;
}

.mt_15 {
    margin-top: 15px;
}

.mt_20 {
    margin-top: 20px;
}

.mt_30 {
    margin-top: 30px;
}

.mt_64 {
    margin-top: 64px;
}

.mt_100 {
    margin-top: 100px;
}

.mt_4 {
    margin-top: 4px;
}

.pt_10 {
    padding-top: 10px;
}

.pt_15 {
    padding-top: 15px;
}

.pt_20 {
    padding-top: 20px;
}

.pt_30 {
    padding-top: 30px;
}

.pt_50 {
    padding-top: 50px;
}

.pt_70 {
    padding-top: 70px;
}

.pr_0 {
    padding-right: 0;
}

.pl_0 {
    padding-left: 0;
}

.pb_30 {
    padding-bottom: 30px;
}

.pb_20 {
    padding-bottom: 20px;
}

.pb_10 {
    padding-bottom: 10px;
}

.pb_5 {
    padding-bottom: 5px;
}

.pb_50 {
    padding-bottom: 50px;
}

.pb_10 {
    padding-bottom: 10px;
}

.pd_10 {
    padding: 10px;
}

.pd_25 {
    padding: 25px;
}

.bg_white {
    background-color: #fff;
}

h4.ml_0 {
    margin-left: 0px;
}

.ml_15 {
    margin-left: 15px;
}

.ml_4 {
    margin-left: 4px;
}

.mrg_0 {
    margin: 0;
}

h4.ml_0 {
    margin-left: 0px;
}

.adv_search_sec {
    padding: 0 25px;
}

.f_12 {
    font-size: 12px;
}

.f_16 {
    font-size: 16px;
}

.f_18 {
    font-size: 18px;
}

.f_40 {
    font-size: 40px !important;
}

.f_24 {
    font-size: 24px !important;
}

.small,
small {
    font-size: smaller;
}

.h_150 {
    height: 150px !important;
}

.h_200 {
    height: 200px !important;
}

.mxw_100,
.mxw_100 .form-select {
    max-width: 100%;
}

.mxw_120 {
    max-width: 120px;
}

.mxw_70,
.mxw_70 .form-select {
    max-width: 70%;
}

.w_100 {
    width: 100%;
}

.mxw_260 {
    max-width: 260px !important;
}

.mxh_520 {
    height: auto;
    max-height: 520px;
}

.mx_760 {
    max-width: 760px;
}

.br_10,
.br_10 .form-select {
    border-radius: 10px;
}

.br_15,
.br_15 .form-select {
    border-radius: 15px;
}

.mb_0 {
    margin-bottom: 0;
}

.mb_8 {
    margin-bottom: 8px;
}

.mb_5 {
    margin-bottom: 5px;
}

.mb_20 {
    margin-bottom: 20px;
}

.mb_30 {
    margin-bottom: 30px;
}

.mb_40 {
    margin-bottom: 40px;
}

.mb_50 {
    margin-bottom: 50px;
}

.mh_270 {
    min-height: 270px;
}

.data_export .mh_420 {
    min-height: 420px;
}

.mh_50 {
    min-height: 50px;
}

b,
strong,
label {
    font-weight: 600;
    margin-bottom: 10px;
}

.text-danger {
    color: #ff0000 !important;
}

.whitebox {
    background: #fff;
    border-radius: 20px;
    padding: 25px 20px;
    margin-bottom: 20px;
}

.box-header {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    margin-bottom: 25px;
}

.box-title {
    margin: 0;
    font-weight: 600;
}

.f_600 {
    font-weight: 600;
}

.f_500 {
    font-weight: 500;
}

.f_400 {
    font-weight: 400;
}

.label_400 label {
    font-weight: 400;
}

.custom_check_box.f_400 label {
    font-weight: 400;
}

.f_22 {
    font-size: 22px;
}

.mb_100 {
    margin-bottom: 100px;
}

.mb_10 {
    margin-bottom: 10px;
}

.mb_30 {
    margin-bottom: 30px !important;
}

.mb_15 {
    margin-bottom: 15px;
}

.ml_20 {
    margin-left: 20px;
}

.ml_25 {
    margin-left: 25px !important;
}

.pt_6 {
    padding-top: 6px;
}

.c_pointer {
    cursor: pointer;
}

.label_title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    display: block;
    margin-bottom: 15px;
}

.welcome-section {
    text-align: center;
    position: relative;
    padding: 60px 0;
}

.text-danger {
    display: inline-block;
    margin-bottom: 10px;
}

.dashboard_task_sec .add_text {
    background: rgba(63, 39, 131, 0.1);
    border: 1px solid #3f2783;
    border-radius: 10px;
    height: 60px;
    font-size: 14px;
    padding: 0 30px;
    max-width: 100%;
}

.dashboard_task_sec .addtask_section .add-field .form-group {
    width: calc(100% - 40px);
    display: inline-block;
}

.pagination-bottom {
    border-top: 1px solid rgba(63, 39, 131, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 120px 40px 20px;
    position: fixed;
    bottom: 0;
    left: 223px;
    width: calc(100% - 223px);
    background: #f3f5f7;
    z-index: 2;
}

.sidebar-collapsed .pagination.pagination-bottom {
    width: calc(100% - 120px);
    left: 104px;
}

.custom_sort_dropdown .custom_dropdown .arrow_img {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
}

.custom_sort_dropdown .custom_dropdown.show .arrow_img {
    transform: rotate(-180deg);
    transition: transform 0.2s linear;
}

.custom_sort_dropdown .custom_dropdown .btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.custom_sort_dropdown .custom_dropdown .dropdown-menu,
.formTimelineSec .custom_dropdown .dropdown-menu {
    width: auto;
    border: 2px solid #3f2783;
    border-radius: 20px;
    background: #fff;
    color: #3f2783;
    z-index: 1;
}

.custom_sort_dropdown .custom_dropdown .dropdown-menu .dropdown-item,
.formTimelineSec .custom_dropdown .dropdown-menu .dropdown-item {
    color: rgba(63, 39, 131, 0.7);
    font-size: 14px;
    padding: 6px 20px;
}

.custom_sort_dropdown .custom_dropdown .dropdown-menu .dropdown-item.active,
.custom_sort_dropdown .custom_dropdown .dropdown-menu .dropdown-item:active,
.formTimelineSec .custom_dropdown .dropdown-menu .dropdown-item.active,
.formTimelineSec .custom_dropdown .dropdown-menu .dropdown-item:active {
    background-color: #e9ecef;
}

.view_all_note .custom_sort_dropdown .custom_dropdown .dropdown-menu {
    left: -96px !important;
}

.formTimelineSec button#dropdown-basic1 {
    position: absolute;
    right: -30px;
    top: -9px;
}

.formTimelineSec button#dropdown-basic1 {
    position: absolute;
    right: -20px;
    top: -10px;
}

.formTimelineSec .dropdown-toggle::after {
    display: none;
}

.formTimelineSec .dropdown-item .action_icons {
    height: 18px;
    width: 18px;
    margin-right: 20px;
}

.formTimelineSec .mp_items .dropdown-item a,
.formTimelineSec .mp_items .dropdown-item span {
    color: #3F2783;
    font-weight: 500;
    display: block;
    font-size: 14px;
}

.Updated_dt {
    font-size: 12px;
    margin-bottom: 0;
}

.action_small_dev .dropdown-item .action_icons {
    margin-right: 20px;
}

.switch-title {
    font-weight: 600;
    margin-bottom: 15px;
    color: #3f2783;
}

.custom_radio_switch input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}

.custom_radio_switch label {
    display: inline-block;
    width: 100px;
    background-color: rgba(63, 39, 131, 0.1);
    color: rgb(63 39 131 / 22%);
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    padding: 14px 10px;
    border: 1px solid #3f2783;
    box-shadow: inset 0 1px 3px rgba(63, 39, 131, 0.2), 0 1px rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease-in-out;
}

.custom_radio_switch input:checked+label {
    box-shadow: none;
    color: #3f2783;
    transition: color 200ms;
    background-color: #fff;
    border: 2px solid #3f2783;
}

.custom_radio_switch.switch_active input:not(:checked)+label {
    box-shadow: none;
    color: rgba(63, 39, 131, 0.2);
    transition: color 200ms;
    background-color: rgba(63, 39, 131, 0.1);
    border: 2px solid rgba(63, 39, 131, 0.2);
}

.custom_radio_switch label:hover {
    cursor: pointer;
}

.custom_radio_switch #switch_left+label {
    border-right-width: 1px;
}

.custom_radio_switch #switch_right+label {
    border-left-width: 1px;
}

.custom_radio_switch.switch_active #switch_left+label {
    border-right-width: 2px;
}

.custom_radio_switch.switch_active #switch_right+label {
    border-left-width: 2px;
}

.custom_radio_switch label:first-of-type {
    border-radius: 20px 0 0 20px;
}

.custom_radio_switch label:last-of-type {
    border-radius: 0 20px 20px 0;
}

.text_area.form-control {
    max-width: 100%;
    height: auto;
    padding: 10px;
}

.form-select:focus {
    border-color: #3f2783 !important;
    outline: none;
    box-shadow: none;
    color: #3f2783;
}

.default_c {
    color: #3f2783;
}

.custom-tbl {
    border-collapse: collapse;
    width: 100%;
}

table.custom-tbl tr .col-action {
    min-width: 180px;
}

.action_large_dev ul {
    /* min-width: 180px; */
}

.page_header {
    padding: 20px 0;
}

.page_header .ph_title {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
}

.tableContainer {
    border-radius: 20px;
    height: 350px;
    padding: 0 10px 10px 10px;
    overflow: auto;
}

.tableContainer table thead {
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
    background: #fff;
}

.tableContainer .infinite-scroll-component {
    overflow: inherit !important;
}

.panel {
    background: #fff;
    border-radius: 20px;
    margin-bottom: 15px;
    min-height: 100px;
}

.panel-heading {
    border-bottom: 1px solid rgba(63, 39, 131, 0.1);
}

.panel-footer {
    border-top: 1px solid rgba(63, 39, 131, 0.1);
}

.pannel_pd {
    padding: 10px 25px;
}

.p_25 {
    padding: 25px;
}

.pt_0 {
    padding-top: 0;
}

.adv_search_sec h4 {
    margin-bottom: 25px;
}

.welcome-section {
    text-align: center;
    position: relative;
    padding: 20px 0 60px 0;
}

.welcome-section h4 {
    font-weight: 600;
    font-size: 24px;
    margin: 20px 0 10px 0;
}

.welcome-section p {
    font-size: 18px;
}

.w-108 {
    width: 108px !important;
}

.char_limit_txt {
    text-align: right;
    color: #3f2783;
}

.upload_details {
    color: #3f2783;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.upload_details .file_icon {
    margin-right: 15px;
}

.upload_details .file_name {
    display: inline-block;
}

.modal-content {
    box-shadow: none;
    border: 2px solid #3f2783;
    border-radius: 20px;
}

.modal-header {
    border-bottom-color: #f4f4f4;
}

.modal-title {
    font-weight: 600;
    font-size: 18px;
}

.terms__condi--content .trms_txt {
    margin-bottom: 10px;
}

.terms__condi--content .trms_txt_sub {
    margin-bottom: 10px;
    padding-left: 10px;
    display: block;
}

.terms__condi--content .trms_txt_sub_sub {
    padding-left: 10px;
    display: block;
}

.terms__condi--content .txt__sub_ttl {
    text-decoration: underline;
}

.terms__content_ppup_bdy {
    max-height: calc(100vh - 130px);
    overflow-y: scroll;
}

.terms__content_ppup_bdy::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #ccc;
}

.terms__content_ppup_bdy::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(63, 39, 131, 0.2);
}

.terms__condi--content .d__block {
    display: block;
}

.dashboard_left_panel {
    width: 275px;
    min-height: 200px;
}

.dashboard_right_panel {
    width: calc(100% - 277px);
    min-height: 200px;
}

.patient_notes_popup .modal-title {
    width: 100%;
}

.scrollbar_vert {
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollbar_hori {
    overflow-y: hidden;
    overflow-x: auto;
}

.scrollbar_vert::-webkit-scrollbar-track {
    border-radius: 10px;
}

.scrollbar_vert::-webkit-scrollbar {
    width: 8px;
    background-color: #fff;
}

.scrollbar_vert::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(63, 39, 131, 0.2);
}

.scrollbar_hori::-webkit-scrollbar-track {
    border-radius: 5px;
}

.scrollbar_hori::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #fff;
}

.scrollbar_hori::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background-color: rgba(63, 39, 131, 0.2);
}

.initial_value {
    color: #ff0000;
}

.recent_value {
    color: #006dff;
}

.carousel-item {
    min-height: 300px;
    margin-bottom: 20px;
}

.carousel-indicators [data-bs-target] {
    width: 10px;
    border-radius: 50%;
    height: 10px;
    background-color: #3f2783 !important;
}

.carousel-control-prev-icon {
    background-image: url(../images/arrow-blue.png);
    transform: rotate(90deg);
}

.carousel-control-next-icon {
    background-image: url(../images/arrow-blue.png);
    transform: rotate(270deg);
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-size: inherit;
    filter: none !important;
}

.CircularProgressbar .CircularProgressbar-text {
    font-weight: 600;
}

.patient_demographic .detail__txt .form-group {
    display: inline-block;
}

.patient_demographic .form-group {
    max-width: 260px;
    width: 100%;
}

.patient_demographic .form-group .form-select {
    border-width: 1px;
    margin: 5px 0;
}

.patient_demographic.form_disabled .form-group .form-select {
    border-width: 0px;
    margin: 5px 0;
}

.patient_demographic.form_disabled input,
.patient_demographic.form_disabled select {
    padding-left: 10px;
    padding-top: 4px;
    border: 0 solid #efefef;
    background: white;
    height: auto;
    color: #000 !important;
    transition: all 0.2s ease-in-out;
}

.patient_demographic .country_c {
    width: 110px;
}

.patient_demographic.form_disabled .country_c {
    width: 54px;
}

.patient_demographic.form_disabled .mobile__num input,
.patient_demographic.form_disabled .country_c input {
    padding-top: 0;
    margin-top: 0;
}

.score_range_slider .range_labels li.active {
    background: #fff;
    border: 2px solid #3F2783;
    color: #3F2783;
}

.score_range_slider .range_labels li.active span {
    opacity: 1;
    visibility: visible;
}

.patient_assessment_form .input_tab_box {
    border: 2px solid #3F2783;
    border-radius: 20px;
    padding: 25px;
    margin-right: 0 !important;
}

.range_sl_title {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
}

.form-group p.rd_label_title {
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
}

.pt_step_rd label {
    font-weight: 400;
}

.pt_privaa_btn {
    text-decoration: underline;
}

.assm_back_btn span {
    display: inline-block;
    color: #3f2783;
    font-size: 24px;
    margin-left: 10px;
}

.button_box {
    margin-top: 20px;
    padding: 30px;
}

.button_box .next_btn {
    width: 212px;
    height: 62px;
    font-size: 24px;
    line-height: 59px;
    border-radius: 50px;
    text-align: center;
}

.assm_back_btn span {
    display: inline-block;
    color: #3f2783;
    font-size: 24px;
}

span.assm_back_btn {
    display: inline-block;
    color: #3f2783;
    background: transparent;
    outline: none;
    text-decoration: none;
    border: 2px solid #3f2783;
    width: 170px;
    height: 56px;
    font-size: 24px;
    line-height: 50px;
    border-radius: 50px;
    text-align: center;
}


button.next_btn.btn_fill span {
    display: none;
}

span.assm_back_btn img {
    display: none;
}


.button_box .next_btn span {
    margin-left: 10px;
    -webkit-transition: all linear 0.3s;
    -moz-transition: all linear 0.3s;
    -ms-transition: all linear 0.3s;
    -o-transition: all linear 0.3s;
    transition: all linear 0.3s;
}

.button_box .next_btn span img:first-child {
    display: none;
}

.button_box .next_btn:hover span img:first-child {
    display: inline-block;
}

.button_box .next_btn:hover span img:last-child {
    display: none;
}

.patient_assessment_form .accordion-item {
    border-radius: 20px;
}

.patient_assessment_form .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-size: 24px;
    font-weight: 600;
}

.patient_assessment_form .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.patient_assessment_form .accordion-button:not(.collapsed) {
    color: #000;
    background-color: #aab3bf00;
    box-shadow: none;
}

.patient_assessment_form .accordion-button:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}

.patient_assessment_form .accordion_tabpanel .accordion-button::after {
    background-image: url("../images/plus.png");
}

.patient_assessment_form .accordion_tabpanel .accordion-button:not(.collapsed)::after {
    background-image: url("../images/minus.png");
    height: 2px;
}

.patient_assessment_form .custom_radio_switch label {
    width: 160px;
}

.patient_assessment_form .accordion_tabpanel .switch-title {
    color: #000;
}

.settings_ppup_main .modal-content {
    min-height: 700px;
}

.settings_ppup_main .modal-header {
    border-color: rgba(63, 39, 131, 0.1);
    padding: 20px;
}

.settings_ppup_main .settings_ppup_bdy {
    padding: 0;
}

.settings_ppup_main .modal-header .btn-close {
    background: transparent url(../images/close-popup.svg) center/1em auto no-repeat;
}

.settings_main .nav-pills .nav-link.active,
.settings_main .nav-pills .nav-link:hover {
    color: #3f2783;
    font-weight: 600;
    background-color: rgba(63, 39, 131, 0.1);
}

.settings_main .nav-pills .nav-link img {
    height: 20px;
    width: 20px;
    margin-right: 8px;
    margin-top: -1px;
}

.settings_main .nav-pills .nav-link .blue_icon {
    display: none;
}

.settings_main .nav-pills .nav-link.active .grey_icon,
.settings_main .nav-pills .nav-link:hover .grey_icon {
    display: none;
}

.settings_main .nav-pills .nav-link.active .blue_icon,
.settings_main .nav-pills .nav-link:hover .blue_icon {
    display: inline-block;
}

.settings_main .language_drp_dwn .form-select {
    border-radius: 30px;
    min-height: 50px;
}

.form-group .selectright {
    background: url(../images/download1.png) no-repeat right;
    background-size: 26px;
    cursor: pointer;
}

.form-group .selectleft {
    background: url(../images/download1.png) no-repeat left;
    background-size: 26px;
    cursor: pointer;
}

.react-datepicker__input-container .form-controlright {
    background: url(../images/calendar.png) no-repeat right;
    background-size: 18px;
    background-position: right 0.75rem center;
    cursor: pointer;
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 10px;
    color: #3f2783;
    padding: 0px 26px 0px 14px;
    height: 40px;
    box-shadow: none;
    max-width: 285px;

}

.react-datepicker__input-container .form-controlleft {
    background: url(../images/calendar.png) no-repeat left;
    background-size: 18px;
    background-position: left 0.75rem center;
    cursor: pointer;
}

#orglisttableDiv {
    border: 1px solid #3f2783;
    border-radius: 20px;
    margin-bottom: 50px;
}

#orglisttableDiv table.custom-tbl thead tr th {
    padding: 16px 10px;
}

#orglisttableDiv table.custom-tbl tbody tr td {
    padding: 14px 10px;
}

#orglisttableDiv table.custom-tbl thead tr .col-orgid {
    width: 30%;
}

#orglisttableDiv table.custom-tbl thead tr .col-orgname {
    width: 50%;
}

#orglisttableDiv table.custom-tbl thead tr .col-status {
    width: 20%;
}

.initial.progress {
    height: 8px;
    ;
}

.recent.progress {
    height: 8px;
    ;
}

.initial .progress-bar {
    background-color: #ff0000;
    height: 8px;
    border-radius: 5px;
    width: 100%;
}

.recent .progress-bar {
    background-color: #006dff;
    height: 8px;
    border-radius: 5px;
    width: 100%;
}

.opn_subtitle {
    border-bottom: 1px solid rgba(63, 39, 131, 0.1);
    padding-bottom: 15px;
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: 600;
}

.opn_subtitle_left {
    padding-bottom: 15px;
    margin: 0 !important;
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: 600;
}

.br_btm {
    border-bottom: 1px solid rgba(63, 39, 131, 0.1);
}

.fullw-separator {
    border-top: 1px solid rgba(63, 39, 131, 0.1);
    margin: 0 -25px;
    width: 103%;
    margin-top: 15px;
    padding-top: 15px;
}

.op_typelist_col {
    margin: 20px 20px 0px 0;
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 20px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    width: 215px;
    height: 225px;
    padding: 10px;
    position: relative;
}

.op_typelist_col .op_type_radio {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    text-align: right;
}

.op_typelist_col .op_type_radio input {
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    position: absolute;
}

.op_typelist_col .op_typelist-content {
    margin-top: 25px;
}

.ideberg_sec .op_typelist_col .op_typelist-content {
    margin-top: 5px;
}

.op_typelist_col [type="radio"]+label {
    padding-right: 3px;
    margin-top: 10px;
}

.op_typelist_col .op_typelist-content p {
    display: block;
    font-weight: 600;
    color: #3f2783;
    margin-top: 15px;
}

.inst_reset_btn {
    position: absolute;
    right: 20px;
    top: 24px;
    font-weight: 600;
    cursor: pointer;
}

.position_rl {
    position: relative;
}

.artho_walch_sec .op_list_Img {
    min-height: 100px;
    margin-bottom: 20px;
}

.artho_gupta_seebauer .op_typelist-content {
    margin-top: 0;
}

.artho_gupta_seebauer .op_typelist_col {
    height: 236px;
}

.artho_gupta_seebauer .mb_10 {
    margin-bottom: 10px !important;
}

.create_newnote_pop .modal-dialog {
    max-width: 1177px;
    margin-top: 100px;
}

.create_newnote_pop .modal-header {
    border-bottom-color: rgba(63, 39, 131, 0.1);
    padding: 20px 30px;
}

.create_newnote_pop .modal-title {
    font-size: 18px;
    color: #000;
    line-height: 24px;
    text-align: center;
}

.create_newnote_pop .form-group {
    margin-bottom: 20px;
}

.create_newnote_pop .create_newnote_pop_bdy.modal-body {
    padding: 20px 30px 40px 30px;
}

.saveedNote_rightPop .search-box,
.view_all_note .search-box,
.nav_inner_search .search-box {
    width: 287px;
    position: relative;
    border: 1px solid rgba(63, 39, 131, 0.7);
    margin: inherit;
    border-radius: 20px;
    height: 40px;
    text-align: center;
    margin-top: 16px;
}

.nav_inner_search .search-box {
    margin-top: 0;
}

.search-box .input-group {
    width: 100%;
}

.search-box .input-group .form-control {
    border: 0;
    height: 36px;
    padding: 0 5px;
    color: #3f2783;
    font-size: 18px;
    margin-top: 0px;
    text-align: center;
}

.search-box .input-group .input-group-addon {
    border: 0;
    background: transparent;
    padding: 0;
    width: 50px;
    text-align: center;
    height: 36px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.search-box .input-group .input-group-addon.first img:last-child {
    display: none;
}

.search-box .input-group.search_active .input-group-addon.first img:last-child {
    display: block;
}

.search-box .input-group.search_active .input-group-addon.first img:first-child {
    display: none;
}

.saveedNote_rightPop .search-box .input-group .form-control,
.view_all_note .search-box .input-group .form-control,
.nav_inner_search .search-box .input-group .form-control {
    font-size: 14px;
}

.form-control:focus {
    color: #3f2783;
    outline: 0;
    box-shadow: none;
}

.search-box .search_close_icon {
    display: none;
}

.search-box .search_active .search_close_icon {
    display: block;
}

.chec_lb_before {
    color: #3f2783;
    font-size: 16px;
    font-weight: 600;

}

.chec_lb_before label {
    margin-bottom: 0;
}

.chec_lb_before input {
    margin: 0 10px;
    width: 16px;
    height: 16px;
}

.time_interval_filed label {
    margin-bottom: 42px;
}

.panel h4 {
    margin: 10px 0px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.satisfaction_screen .satis_drp label {
    font-weight: 400;
}

.satisfaction_screen .satis_drp .form-select {
    max-width: 300px;
}

.complete_screen .thanks_txt {
    font-size: 18px;
    margin-bottom: 40px;
}

.complete_screen .cmp_submit_btn,
.result_screen .result_submit_btn {
    width: 220px;
    height: 62px;
    font-size: 20px;
    border-radius: 30px;
    margin-bottom: 20px;
}

.complete_screen .answer_txt {
    font-size: 12px;
    display: block;
}

.result_screen .answer_txt {
    font-size: 16px;
    text-align: center;
    margin-bottom: 50px;
}

.forms_map_screen .button_box {
    padding: 30px 0;
}

.forms_map_screen .ph_title {
    margin-bottom: 60px;
}

.location_ select.form-control.form-select {
    border: 2px solid rgba(63, 39, 131, 0.7);
    min-width: 140px;
    font-size: 14px;
}

.content__main_reg {
    margin-top: 10vh;
}

.assm_back_btn img {
    margin-bottom: 7px;
}

.model_width {
    max-width: 80% !important;
}

.box_d {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 10px 15px 13px 15px;
    width: 100%;
    /* height: 260px; */
    margin: 10px;
    min-height: 250px;
}

.assm_back_btn img {
    display: none;
}

#case-list-tabs-tab-allcases,
#case-list-tabs-tab-submitted {
    color: #3f2783;
}

.org_tab .nav-tabs .nav-link:focus,
.org_tab .nav-tabs .nav-link:hover {
    border-color: rgba(63, 39, 131, .7);
}

.org_tab .nav-tabs>li>button.active {
    border: 2px solid rgba(63, 39, 131, .7);
    z-index: 1;
}


.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    color: #495057;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
    isolation: isolate;
}

.org_tab .nav-tabs>li>button {
    background-color: #fff;
    border: 2px solid rgba(63, 39, 131, .7);
    border-bottom: none !important;
    border-radius: 20px 20px 0 0;
    cursor: default;
    display: block;
    font-size: 14px;
    font-weight: 600;
    height: 48px;
    line-height: 24px;
    /* margin-right: 15px; */
    padding: 10px 15px;
    position: relative;
}

#case-list-tabs-tabpane-allcases.active.show,
#case-list-tabs-tabpane-submitted.active.show {
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
    background: #fff;
}

.org_tab .tab-content,
.org_tab .tab-content>div {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

.org_tab .tab-content {
    margin-bottom: 100px;
    margin-top: -1px;
    padding: 0 !important;
    max-width: 100% !important;
}

.home_tab .tab-content,
.home_tab .tab-content>div {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

.home_tab .tab-content {
    margin-bottom: 100px;
    margin-top: -1px;
    padding: 0 !important;
    max-width: 100% !important;
}

#case-list-tabs-tab-allcases {
    width: 97%;
}

#case-list-tabs-tab-submitted {
    width: 100%;
}

.org_tab .tab-content,
.org_tab .tab-content>div {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 0px;
}

.home_tab .tab-content,
.home_tab .tab-content>div {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 0px;
}

.btn_view {
    border: 2px solid #3f2783;
    color: #3f2783;
    border-radius: 50px;
    font-size: 14px;
    padding: 0 22px;
    min-width: 220px;
    text-align: center;
    font-weight: 500;
    height: 40px;
    line-height: 37px;
    cursor: pointer;
    background: transparent;
}

.f_25 {
    font-size: 25px;
}

.MyVisits_visits_right_btn__2nwZe {
    /* width: 50px; */
    text-align: right;
}

.table_color {
    border-color: 1px #ccc;
    text-align: center;
    border-color: rgba(63, 39, 131, 0.1) !important;
}

.no_data {
    padding: 20px;
    text-align: center;
    font-size: 18px;
    color: #8f8e8e;
    padding-bottom: 100px;
    padding-top: 100px;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
    border-top: 0px solid rgba(173, 173, 173, 0.2) !important;
    margin-bottom: 60px;
    position: relative;
    bottom: 0px;
}

.username-info span {
    font-size: 12px;
    opacity: 1;
    overflow-wrap: break-word;
}

.pathology_page .img_box {
    min-height: 90px;
}

.pathology_page .dt_individual_top_sec .whitebox h4 {
    padding-top: 27px;
    padding-bottom: 15px;
}

.btn-outline-common {
    border: 2px solid #3f2783;
    color: #3f2783;
    border-radius: 50px;
    font-size: 18px;
    padding: 0 22px;
    height: 40px;
    line-height: 40px;
    background: transparent;
}

.pathology_page {
    padding-top: 100px;
}

.box-content {
    max-height: 308px;
    padding: 40px 20px;
    height: 301px;
}

.box_d h4 {
    word-break: break-all;
}

.box_d strong {
    font-size: 62px !important;
}

.box_d h4 {
    font-size: 24px !important;
}

.box_d label strong {
    font-size: 20px !important;
}

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 9px;
    height: 40px;
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 10px;
    color: rgb(63, 39, 131);
    padding: 0px 15px;
    margin-bottom: 12px;
    width: 100%;
    box-shadow: none;
    z-index: 1;
}

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0px !important;
    font-size: 18px;
    margin: auto;
}

.css-1qqsdnr-MuiAutocomplete-root {
    max-width: 653px !important;

}

.bPNNyh>svg {
    fill: #bdb8b8 !important;
}

.hCpHso .selected {
    background-color: #bbb6b642 !important;
}

.fBLtRQ {
    min-height: 33px !important;
}

.fBLtRQ>input {
    font-size: 14px !important;
}

.fBLtRQ>input ::placeholder {
    color: #3f2783 !important;
}

/* span.white_shade {
    width: 12px;
    height: 18px;
    background-color: #ffffff;
    position: absolute;
    right: 12px;
    top: 11px;
    z-index: 3;
    border-radius: 57px;
} */
.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 2px !important;
    background-color: #ffff !important;
}

.MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root {
    border: 2px solid rgba(63, 39, 131, .7) !important;
}

.MuiAutocomplete-root.Mui-focused .MuiFormControl-root .MuiInputBase-root {
    border: 2px solid #3f2783 !important;
}

.MuiAutocomplete-root.Mui-focused .css-1bp1ao6.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
    border-bottom: none !important;
}

.panel-body.p_25 h2 {
    font-size: 24px;
}


.content__main_arabic {
    padding: 24px;
    margin-top: 72px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-right: 222px;
    /* overflow-y: auto; */
    background-color: #f3f5f7;
}

div#pdf-controls {
    /* display: none; */
    box-shadow: none;
}
a#pdf-download{
    display: none !important;
}

div#header-bar {
    display: none;
}

.btn_green {
    border: 2px solid #1DCE00;
    color: #1DCE00;
    border-radius: 50px;
    font-size: 14px;
    padding: 0 22px;
    height: 40px;
    line-height: 38px;
    background: transparent;
}

.btn_green:hover {
    background: #1DCE00 !important;
    color: #fff !important;
}

.reminder_box {
    max-height: 200px;
    overflow: auto;
}

@media (min-width: 768px) {
    .modal-dialog.modal-md {
        max-width: 600px;
        margin: 30px auto;
    }

    .box_d {
        position: relative;
        border: 1px solid #ccc;
        border-radius: 20px;
        padding: 10px 15px 13px 15px;
        width: 100%;
        /* height: 260px; */
        margin: 10px;
        min-height: 282px;
    }
}

@media (max-width: 768px) {

    .box_d {
        position: relative;
        border: 1px solid #ccc;
        border-radius: 20px;
        padding: 10px 15px 13px 15px;
        width: 100%;
        /* height: 260px; */
        margin: 10px;
        min-height: 282px;
    }
}

@media only screen and (min-width: 1401px) {
    .dashboard_left_panel {
        width: 415px;
    }

    .dashboard_right_panel {
        width: calc(100% - 417px);
    }
}

@media only screen and (min-width: 1700px) {
    .dashboard_right_panel {
        width: calc(1347px - 417px);
    }
}

@media only screen and (max-width: 1199px) {
    table.custom-tbl tr .col-action {
        min-width: auto;
        padding-right: 15px;
    }

    #sidebar_main.pro-sidebar {
        width: 100%;
        height: 100px;
        margin-top: 0;
        position: fixed;
        bottom: 0;
    }

    .content__main {
        margin-left: 0;
    }

    .pro-sidebar .pro-menu ul {
        display: flex;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
        flex-direction: column;
        padding: 8px;
    }

    .pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
        overflow-y: hidden;
        overflow-x: auto;

    }

    #sidebar_main.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
        margin-right: 0;
    }

    .pro-sidebar-header {
        display: none;
    }

    #sidebar_main.pro-sidebar>.pro-sidebar-inner {
        background: #3f2783;
        border-radius: 0 0 0 0;
    }

    #sidebar_main.pro-sidebar.collapsed {
        width: 100%;
        min-width: 100%;
    }

    .rp_100 {
        width: 100%;
    }

    .settings_ppup_main .modal-dialog.modal-lg {
        max-width: 96%;
    }
}

@media only screen and (min-width: 1200px) {
    table.custom-tbl tr:hover .action_large_dev {
        display: block;
    }

    .settings_ppup_main .modal-dialog.modal-lg {
        max-width: 1177px;
        margin-top: 106px;
        margin-bottom: 106px;
    }

    .patient_assessment_form.fixed_vertical_menu .navbar {
        position: fixed;
        top: 30px;
    }
}

@media only screen and (min-width: 1250px) {
    .content_fx_w {
        margin: 0 auto;
        width: 1236px;
    }
}

@media only screen and (min-width: 992px) {
    .nav_inner_search .search-box {
        width: 400px;
    }

    .nav_inner_search .search-box .input-group {
        justify-content: space-between
    }
   

}
@media only screen and (max-width: 860px) {
    .timeline_border {
        width: 59px !important;
        font-size: 11px !important;
    }

}
@media (min-width: 1400px) and (max-width : 1500px){
    .FormTimeline_stepProgress__1Z8gu li p {
        margin-bottom: 10px !important;
        text-align: justify;
        margin-top: 8px;
    }
    .icon_border {
        /* width: 90px !important; */
        font-size: 11px !important;
    }
    .FormTimeline_stepProgress__1Z8gu {
        padding-left: 38px !important;
    }
    .timeline_border {
         width: 88px !important;
        font-size: 11px !important;
    }
}

.dropdown_main_1 {
    width: 100%;
    display: flex;
    position: relative;
}

.dropdown_main_1 input {
    padding: 0 0 0 13px !important;
    background-color: rgba(0, 0, 0, 0);
    font-size: 14px;

    font-family: poppins;

}

.dropdown_main_1 input:active {
    border: none !important;
}

.dropdown_main_1 input:hover {
    border: none !important;
    background-color: #fff !important;
    outline: none !important;
}

.dropdown_main_1 .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
    outline: none !important;

}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #fff !important;
    border-width: 0px !important;

}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #fff !important;
    border-width: 0px !important;
}

.dropdown_main_1 .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root {
    border: 2px solid rgba(63, 39, 131, .7) !important;
    border-radius: 10px;
}

.dropdown_main_1 .MuiAutocomplete-root .Mui-focused .MuiFormControl-root .MuiInputBase-root .Mui-focused {
    border: 2px solid rgba(63, 39, 131, .7) !important;
}



.dropdown_main_1 .MuiAutocomplete-root.Mui-focused .MuiFormControl-root .MuiInputBase-root.Mui-focused {
    border: 2px solid #3f2783 !important;
    outline: none !important;
}

.dropdown_main_1 .MuiAutocomplete-root.Mui-focused .css-1bp1ao6.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
    outline: none !important;


}


.dropdown_main_1 .css-xxees4 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 26px !important;
    background-color: #fff !important;
}

.css-xxees4 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 32px !important;
    background-color: #fff !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #fff !important;

}

.msg-box {
    font-size: 15px;
    border: 1px solid #3f2783;
    border-radius: 8px;
    padding: 10px;
    text-align: justify;
    margin-bottom: 1rem;
}

.css-igs3ac {
    border-color: #fff !important;
    border-width: 0px !important;
}

.delete_modal_main .center_title {
    text-align: center;
    font-size: 14px;
    padding: 20px 0;
    font-weight: normal;
    margin-bottom: 0;
}

.delete_modal_main .btn_outline {
    width: 140px;
    margin-right: 15px;
}

.delete_modal_main .btn_outline {
    width: 140px;
    margin-right: 15px;
}

.delete_modal_main .modal-footer {
    justify-content: center;
}

.dob_disable {
    background-color: #D9D4E6 !important;
}

.assm_back_btn {
    width: 212px;
    height: 62px;
    font-size: 24px;
    display: block;
    line-height: 59px;
    border-radius: 50px;
    text-align: center;
    background: transparent;
    color: #3f2783;
    outline: none;
    text-decoration: none;
    border: 2px solid #3f2783;
}

.assm_back_btn:hover {
    color: #fff;
    background: #3f2783;
}

.assm_back_btn:hover span {
    color: #fff;

}

.drop_bacground {
    padding: 5px;
    color: #000;
    font-size: 12px;
    background: transparent;
    -webkit-appearance: none;
}


.card-border {
    border: 2px solid #3f2783;
    margin: 5px;
    height: auto;
    border-radius: 5px;
}

.card-border:hover {
    box-shadow: 1px 4px 9px 4px #3f278340;
}

.add-btn {
    position: relative;
    left: -37px;
}

.patient-map-name {
    height: 183px;
    background-color: #3f27837a;
    color: #fff;
    font-weight: 600;
    align-items: center;
    display: grid;
}

.patient-map-app {
    align-items: center;
    display: grid;
    text-align: center;
    height: 183px;
}



.card-borderss {
    border: 2px solid rgb(6, 6, 6);
    /* Replace "red" with your desired border color */

    display: flex;
    /* Add flex display */
    justify-content: center;
    /* Center the content horizontally */
    align-items: center;
    /* Center the content vertically */
    /* You can also add more styles to customize the appearance of the cards */
}

.patient_imgS:hover {
    transform: scale(1.1);

}

.patient_imgSS:hover {
    border: 2px solid #D9D4E6;
    background-color: #cccccc36;
}

.patient_imgS {
    transition: all 0.2s ease-in;
}

.heart_ico {
    width: 31px;
    margin-bottom: 9px;
    margin-left: 6px;
}


.select__control.css-1s2u09g-control {
    border: 2px solid rgba(63, 39, 131, .7);
    border-color: #3f2783b5 !important;
    border-radius: 10px;
}

.basic-multi-select.css-b62m3t-container .select__control.css-1s2u09g-control {
    border-radius: 10px;
}

.basic-multi-select.css-b62m3t-container .css-1pahdxg-control:hover {
    border-radius: 10px !important;
}

.basic-multi-select.css-b62m3t-container .css-1pahdxg-control:focus {
    border-radius: 10px !important;
}

.basic-multi-select.css-b62m3t-container .css-1pahdxg-control {
    border-radius: 10px !important;
}

.css-1pahdxg-control {
    border: 2px solid rgba(63, 39, 131, 0.7) !important;
    border-radius: 19px !important;
    border-color: #3f2783b5 !important;
    box-shadow: none !important;
}

.css-1pahdxg-control:focus {
    border: 2px solid rgba(63, 39, 131, 0.7) !important;
    border-radius: 10px !important;
    border-color: #3f2783b5 !important;
    box-shadow: none !important;
}

.main_select.css-1pahdxg-control:hover {
    border: 2px solid rgba(63, 39, 131, 0.7) !important;
    border-radius: 10px !important;
    border-color: #3f2783b5 !important;
    box-shadow: none !important;
}

.css-1pahdxg-control:hover {
    border: 2px solid rgba(63, 39, 131, 0.7) !important;
    border-radius: 10px !important;
    border-color: #3f2783b5 !important;
    box-shadow: none !important;
}

.css-14el2xx-placeholder {
    color: #3f2783 !important;
    grid-area: 1/1/2/3;
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
}

.fc .fc-button-primary {
    background-color: #ffffff !important;
    border-color: #3f2783 !important;
    color: #3f2783;
}

.css-1s2u09g-control {
    border: 2px solid rgba(63, 39, 131, 0.7) !important;
    border-radius: 10px !important;
}

.arrow-bright {
    display: none;
}

.bordertopH:hover {
    border: 1px solid var(--20, #D9D4E6);
}

.bordertopH:hover .arrow-bright {
    display: block;

}
.icn_img {
    width: 40px;
    margin-right: 15px;
}



/* My Journey CSS-----Soummya Biswas */


.journey-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center
}


.journey-baground {
    width: 99px;
    height: 93px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--10, #ECE9F3);
    border-radius: 20px
}

.journey-content .journey-baground img {
    width: 60px;
    height: 60px;
    z-index: 2;
    cursor: pointer;
}

/* .journey-box {
    width: 363px;
    height: 260px;
    border-radius: 20px;
    border: 2px;
    margin: 10px 18px;
} */

.welcome-section-doc {
    text-align: center;
    position: relative;
    padding: 20px 0 60px 0;
}

.welcome-section-doc h4 {
    font-weight: 700;
    font-size: 39px;
    margin: 20px 0 10px 0;
    font-family: 'Poppins';
    line-height: normal;
}

.white_img img {
    width: 23.333px;
    height: 30px;
    flex-shrink: 0;
    
}

.white_img {
    width: 163.12px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 27.6471px;
    margin: auto;
    text-align: center;
}


.doc_code_card{
    text-align: center;
    font-weight: 500;
    font-size: 15px;
}



.card_individual_sec{
    /* padding-top: 60px; */
    padding-top: 0px;
  
  }
  .card_individual_sec .whitebox{
    max-height: 242px;
    padding: 40px 20px;
  }
  .card_individual_sec .whitebox h4 {
    font-size: 24px;
    font-weight: 600;
    padding-top: 10px;
    color: #000000;
  
  }
  .card_individual_sec .whitebox p {
    margin-bottom: 25px;
    font-size: 12px;
    line-height: 17.92px;
  }
  .card_individual_sec button.btn_outline {
    font-size: 14px;
    min-width: 126px;
    height: 38px;
    line-height: 35px;
  }




  input.searching::placeholder {
    text-align: -webkit-left;
}

.f_doc_code{
    color: #000;

font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.f_search_doc_code{
    color: #000;

text-align: center;
font-family: Poppins;
font-size: 14px !important;
font-style: normal;
font-weight: 400;
line-height: normal !important;
}
.circle_para{
    font-size: 12px;
    font-weight: 500;
}
table.custom-tbl.width_op thead {
    z-index: 1;
}
.home_name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 103px;
    width: 120px;
    
}
.icon_border {
    width: max-content !important
   
}
.timeline_border{
    display: flex;
    border-radius: 20px;
    border: 1px solid;
    width: 145px;
    height: 30px;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
}
.home_box{
    max-width: 100%;
}
.model_head {
    width: 40%;
  }
  p.model_text {
    width: 60%;
  }
  .more_ {
    border: 1px solid;
    border-radius: 40px;
    width: fit-content;
    padding: 1px 5px;
    text-transform: lowercase;
    cursor: pointer;
    height: 16px;
    font-size: 10px;
    line-height: 12px;
}
.itm_cicle{
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #3f2783;
    display: block;
    margin-left: 13px;
    margin-right: 13px;
    position: relative;
}
.height_model .modal-content {
    min-height: auto;
}